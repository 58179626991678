import { Atom } from ":mods";
export function Feedback() {
  return (
    <section class="flex flex-col bg#paper items-center">
      <div class="flex gap-80px justify-center items-center my-80px w-845px">
        <p class="cursor-pointer">{"<"}</p>
        <div class="flex flex-col gap-40px justify-center items-center w-710px">
          <Atom.Microsite.Title>What our colleagues say about working with us</Atom.Microsite.Title>
          <div class="flex gap-20px mt-40px">
            <img src="/images/saif_pfp.jpg" class="h-59px w-59px rounded-50% border-2 border#p border-solid" />
            <div>
              <article class="text-21px font-normal">
                “"The environment, course design and outcome is simply brilliant."
              </article>
              <p class="font-bold text-24px">- Saif Chaudhry</p>
            </div>
          </div>
        </div>
        <p class="cursor-pointer">{">"}</p>
      </div>

      <hr class="max-w-screen w-screen h-2px bg#p" />
    </section>
  );
}
